<template>

    <div>

        <XModal ref="XModal" :show-overlay="true">

            <template #header>
                Просмотр карточки абонента
            </template>
            <template #default>

                <div :style="{minWidth: '30vw', maxWidth: '80vw'}">

                    <table class="p-datatable-table" style="width: 100%;">
                        <tbody>

                        <tr>
                            <td>GUID организации</td>
                            <td>{{ itemData.guid }}</td>
                        </tr>
                        <tr>
                            <td>Наименование</td>
                            <td>{{ itemData.title }}</td>
                        </tr>
                        <tr>
                            <td>Адрес КП СЗИ</td>
                            <td>{{ itemData.old_medo_address }}</td>
                        </tr>
                        <tr>
                            <td>Тип абонента</td>
                            <td>{{ itemData.type }}</td>
                        </tr>
                        <tr>
                            <td>Организация</td>
                            <td>{{ itemData.organization }}</td>
                        </tr>
                        <tr>
                            <td>Ответственное лицо</td>
                            <td>{{ itemData.authority }}</td>
                        </tr>
                        <tr>
                            <td>Телефон</td>
                            <td>{{ itemData.phone }}</td>
                        </tr>
                        <tr>
                            <td>Электронная почта</td>
                            <td>{{ itemData.email }}</td>
                        </tr>
                        <tr>
                            <td>GUID оператора</td>
                            <td>{{ itemData.operatorUid }}</td>
                        </tr>
                        <tr>
                            <td>Готов к работе</td>
                            <td>{{ (itemData.isActive) ? 'Да' : 'Нет' }}</td>
                        </tr>
                        <tr>
                            <td>Аттестован под работу с документами ДСП</td>
                            <td>{{ (itemData.isSecure) ? 'Да' : 'Нет' }}</td>
                        </tr>

                        <tr>
                          <td>Предпочтительный формат МЭДО</td>
                          <td>{{ itemData?.iedms_version ?? 'Не задан' }}</td>
                        </tr>


                        </tbody>
                    </table>

                  <pre v-if="this.$store.state.debugModeOn"
                       style="font-size: 0.9rem; color: var(--gray-500)">{{ itemData }}</pre>

                </div>


            </template>

            <template #footerLeftOfHide>
                <Button v-if="addSelectAbonentButton" class="p-button-success p-button-outlined" label="Выбрать"
                        @click="abonentSelected"/>
            </template>


        </XModal>

    </div>

</template>

<script>
import {XModal} from "@xnpmpackages/xcomponents";

export default {
    name: "AbonentCard",
    components: {XModal},
    emits: ['abonentSelected'],
    props: {
        addSelectAbonentButton: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            itemData: null
        }
    },
    methods: {
        show($itemData) {
            this.itemData = $itemData;
            this.$refs.XModal.show();
        },
        async abonentSelected() {
            await this.$refs.XModal.hide();
            this.$emit('abonentSelected', this.itemData);
        },
    },
}
</script>

<style scoped>

.p-datatable-table {
    border-collapse: collapse;
}

.p-datatable-table tbody > tr > td, .p-datatable-table thead > tr > td {
    padding: 5px 20px;
}

.p-datatable-table > tbody > tr:nth-child(even) {
    /*background-color: #0a3622;*/
    background-color: var(--surface-ground);
}

</style>
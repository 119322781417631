<template>

  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle"/>
    <div class="layout-sidebar" @click="onSidebarClick">

      <ScrollPanel style="width: 100%; height: 100%">
        <AppMenu :model="menu" @menuitem-click="onMenuItemClick"/>
      </ScrollPanel>

    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <Message v-if="this.$store.state?.headerAnnouncement?.elapsedChangePasswordAnnounce">
          {{ this.$store.state?.headerAnnouncement?.elapsedChangePasswordAnnounce }}
        </Message>
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component"/>
          </keep-alive>
        </router-view>
      </div>
      <AppFooter/>
    </div>

    <!--        <AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange"/>-->
    <transition name="layout-mask">
      <div v-if="mobileMenuActive" class="layout-mask p-component-overlay"></div>
    </transition>

    <Notifies/>
    <BlockNotify/>
    <ConfirmPopup/>
  </div>

  <Toast ref="toast"/>

  <ConfirmDialog></ConfirmDialog>


</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
import AppConfig from './AppConfig.vue';
import AppFooter from './AppFooter.vue';
import MenuService from "./service/MenuService";
import {AppEventBus, BlockNotify, Notifies} from "@xnpmpackages/xcomponents";

export default {

  provide() {
    return {}
  },
  created() {
    this.menuService = new MenuService();
    window.addEventListener('keydown', (e) => {
      if (e.altKey && e.ctrlKey && e.key === 'd') {
        this.$store.commit('setDebugModeOn', !this.$store.state.debugModeOn)
      }
    });
  },
  async mounted() {

    this.menuService.getMenuItems().then(data => {
      this.menu = data;
    });

    AppEventBus.on('updateMenu', data => {
      this.menu = data;
    });

  },
  data() {
    return {
      isLoggedIn: false,
      modalInfoVisible: true,
      modalInfoContent: '',

      menuService: null,

      layoutMode: 'static',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: []
    }
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    }
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList)
        element.classList.add(className);
      else
        element.className += ' ' + className;
    },
    removeClass(element, className) {
      if (element.classList)
        element.classList.remove(className);
      else
        element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static')
          return !this.staticMenuInactive;
        else if (this.layoutMode === 'overlay')
          return this.overlayMenuActive;
      }

      return true;
    }
  },
  computed: {

    containerClass() {
      return ['layout-wrapper', {
        'layout-overlay': this.layoutMode === 'overlay',
        'layout-static': this.layoutMode === 'static',
        'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
        'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
        'layout-mobile-sidebar-active': this.mobileMenuActive,
        'p-input-filled': this.$primevue.config.inputStyle === 'filled',
        'p-ripple-disabled': this.$primevue.config.ripple === false
      }];
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden');
    else
      this.removeClass(document.body, 'body-overflow-hidden');
  },
  beforeCreate() {
    //this.$store.dispatch('getServerLimits');
    this.$store.dispatch('getPublicParams');
    this.$store.dispatch('getApplicationVersion');
  },
  components: {
    'BlockNotify': BlockNotify,
    'AppTopBar': AppTopBar,
    'AppMenu': AppMenu,
    'AppConfig': AppConfig,
    'AppFooter': AppFooter,
    'Notifies': Notifies,
  }
}
</script>

<style lang="scss">
@import './App.scss';
</style>
<style>
.p-password.w-full > input.p-password-input {
  width: 100% !important;
}
</style>

export default class MenuService {

    async getMenuItems() {

        return [
            {
                "label": "",
                "items": [
                    {
                        "label": "Запросы в ГАС",
                        "icon": "fas fa-file-export",
                        "to": "/gas-requests"
                    },
                    {
                        "label": "Запросы в НСИ",
                        "icon": "fas fa-file-export",
                        "to": "/nsi-requests"
                    },
                    {
                        "label": "Запросы в СППР",
                        "icon": "fas fa-file-export",
                        "to": "/sppr-requests"
                    },
                    {
                        "label": "Отчеты из СППР",
                        "icon": "fas fa-file-import",
                        "to": "/sppr-reports"
                    },
                    {
                        "label": "Справочник ГАС",
                        "icon": "fas fa-address-book",
                        "to": "/gas-reference"
                    },
                    {
                        "label": "Справочники НСИ",
                        "icon": "fa-solid fa-book",
                        "to": "/nsi-reference"
                    },
                    {
                        "label": "Конвертер документов",
                        "icon": "fas fa-file-signature",
                        "to": "/doc-converter"
                    },
                    {
                        "label": "Редактор документов",
                        "icon": "fa-solid fa-pen-to-square",
                        "to": "/selector/download"
                    },
                    {
                        "label": "Сведения об организации",
                        "icon": "fas fa-building",
                        "to": "/organization-info"
                    },
                    {
                        "label": "Настройки",
                        "icon": "fas fa-gears",
                        "to": "/settings"
                    },
                ]
            }
        ];

    }

}